'use client';

import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
export const Oauth2Closer = () => {
  const searchParams = useSearchParams();
  useEffect(() => {
    const needClosed = searchParams.get('oauth');
    if (needClosed && needClosed === 'success') {
      window.close();
    }
  }, [searchParams]);
  return null;
};